import React from 'react';

import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useState } from "react";



const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const usernameHandler = (event) => {
    console.log(event.target.value);
    setUsername(event.target.value);
  }
  const passwordHandler = (event) => {
    console.log(event.target.value);

    setPassword(event.target.value);
  }

  async function submitHandler(event) {
    event.preventDefault();


    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.stopPropagation();
    } else {

    }
  }

  return (
    <Container fluid className={`${props.className}`}>
      <Row className="pt-4 justify-content-center">
        <Col {...props.displaySettingsLoginCol} className="">
          <Form onSubmit={submitHandler}>
            <Form.Control
              type="text"
              placeholder="Enter Username"
              className="mt-2"
              value={username}
              onChange={usernameHandler}
            />
            <Form.Control
              type="password"
              placeholder="Password"
              className="mt-2"
              value={password}
              onChange={passwordHandler}
            />
            <div className="d-grid gap-2">
              <Button variant="dark" type="submit" className="mt-2 d-inline">
                Einloggen
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
