import { Container, Row, Col } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const Fotos = (props) => {
  const images_d = [];
  const images_b = [];
  const images_s = [];

  //images denise
  for (let i = 0; i < 135; i++) {
    images_d[i] = {
      original: "https://bloede-idee.de/bilder/fotos_d/b" + (i + 1) + ".jpeg",
      thumbnail: "https://bloede-idee.de/bilder/thumbs_d/t" + (i + 1) + ".jpeg",
      loading: "lazy",
      thumbnailLoading: "lazy",
    };
  }

  //images box
  for (let i = 0; i < 108; i++) {
    images_b[i] = {
      original: "https://bloede-idee.de/bilder/fotos_b/b" + (i + 1) + ".jpeg",
      thumbnail: "https://bloede-idee.de/bilder/thumbs_b/t" + (i + 1) + ".jpeg",
      loading: "lazy",
      thumbnailLoading: "lazy",
    };
  }

    //images standesamt
    for (let i = 0; i < 91; i++) {
      images_s[i] = {
        original: "https://bloede-idee.de/bilder/fotos_s/b" + (i + 1) + ".jpeg",
        thumbnail: "https://bloede-idee.de/bilder/thumbs_s/t" + (i + 1) + ".jpeg",
        loading: "lazy",
        thumbnailLoading: "lazy",
      };
    }

  return (
    <Container fluid className={`${props.className}`}>
      <Row className="pt-4 justify-content-center">
        <Col {...props.displaySettingsMain} className="text-center">
          <h4>Bilder unserer Fotografin</h4>{" "}
          <div>Auswahl der Fotobox- und Standesamtbilder weiter unten</div>
        </Col>
      </Row>
      <Row className="pt-4 justify-content-center mb-4">
        <Col {...props.displaySettingsMain} className="text-center">
          <ImageGallery items={images_d} lazyLoad={true} showIndex={true} />
        </Col>
      </Row>
      <Row className="pt-4 justify-content-center mt-4">
        <Col {...props.displaySettingsMain} className="text-center">
          <h4>Fotobox</h4>{" "}
        </Col>
      </Row>
      <Row className="pt-4 justify-content-center">
        <Col {...props.displaySettingsMain} className="text-center">
          <ImageGallery items={images_b} lazyLoad={true} showIndex={true} />
        </Col>
      </Row>
      <Row className="pt-4 justify-content-center mt-4">
        <Col {...props.displaySettingsMain} className="text-center">
          <h4>Standesamt</h4>{" "}
        </Col>
      </Row>
      <Row className="pt-4 justify-content-center">
        <Col {...props.displaySettingsMain} className="text-center">
          <ImageGallery items={images_s} lazyLoad={true} showIndex={true} />
        </Col>
      </Row>
    </Container>
  );
};

export default Fotos;
