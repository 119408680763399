import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./layout/Header";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Event from "./components/Event";
import Contact from "./components/Contact";
import Fotos from "./components/Fotos";
import Locations from "./components/Locations";
import Rsvp from "./components/Rsvp";
import Welcome from "./components/Welcome";
import Login from "./components/Login";
import Guide from "./components/Guide";
import Useful from "./components/Useful";
import "./App.css";

import { Amplify } from 'aws-amplify';

import { I18n } from 'aws-amplify';


import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsconfig from './aws-exports';
Amplify.configure(awsconfig);


//display settings
const DISPLAY_SETTINGS_MAIN = {
  lg: "12",
  xl: "8",
  xxl: "6",
};

const DISPLAY_SETTINGS_TWO = {
  lg: "6",
  xl: "4",
  xxl: "3",
};

const DISPLAY_SETTINGS_THREE_COL = {
  md: "4",
};

const DISPLAY_SETTINGS_FOUR_COL = {
  md: "3",
};

const DISPLAY_SETTINGS_LOGIN_COL = {
  md: "2",
};


function App() {
  I18n.putVocabulariesForLanguage('de', {
    'Sign In': 'Einloggen', // Tab header
    'Sign in': 'Einloggen', // Button label
    'Sign in to your account': 'Welcome Back!',
    'Signing in': 'Einloggen...',
    Username: 'Nutzername', // Username label
    Password: 'Passwort', // Password label
    'Forgot your password?': 'Reset Password',
  });
  

  const components = {
    SignIn: {
      Header() {
        return (
          <div className="text-center align-center"><img src='weddingLogo.png' className="mt-5 mb-2 w-75"></img></div>
        );
      },

      Footer() {
        return (
          <div></div>
        );
      },
    },
  };

  //       <Header onSignOut={signOut}/>
//     <Authenticator>   s

  return (
     <Authenticator components={components}>
      {({ signOut }) => (
            <div>
      <Header onSignOut={signOut}/>
      <Container fluid className="">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login displaySettingsLoginCol={DISPLAY_SETTINGS_LOGIN_COL} />}/>
          <Route path="/" element={<Welcome displaySettingsMain={DISPLAY_SETTINGS_MAIN}/>} />
          <Route path="/welcome" element={<Welcome displaySettingsMain={DISPLAY_SETTINGS_MAIN} />}/>
          <Route path="/event" element={<Event  displaySettingsMain={DISPLAY_SETTINGS_MAIN}/>}/>
          <Route path="/locations" element={<Locations  displaySettingsMain={DISPLAY_SETTINGS_MAIN} displaySettingsThreeCols={DISPLAY_SETTINGS_THREE_COL}/>}/>
          <Route path="/rsvp" element={<Rsvp  displaySettingsMain={DISPLAY_SETTINGS_MAIN} displaySettingsTwo={DISPLAY_SETTINGS_TWO}/>}/>
          <Route path="/fotos" element={<Fotos  displaySettingsMain={DISPLAY_SETTINGS_MAIN}/>}/>
          <Route path="/useful" element={<Useful  displaySettingsMain={DISPLAY_SETTINGS_MAIN}/>}/>
          <Route path="/guide" element={<Guide  displaySettingsMain={DISPLAY_SETTINGS_MAIN}/>}/>
          <Route path="/contact" element={<Contact  displaySettingsMain={DISPLAY_SETTINGS_MAIN} displaySettingsFourCols={DISPLAY_SETTINGS_FOUR_COL}/>}/>
        </Routes>
      </BrowserRouter>
      </Container>
      </div>

)}
       </Authenticator>
 
  );
}
export default App;




