import { Container, Row, Col } from "react-bootstrap";

const Guide = (props) => {
  return (
    <Container fluid className={`${props.className}`}>
      <Row className="pt-4 justify-content-center">
        <Col {...props.displaySettingsMain} className="text-center">
          <h3>Das Münsterland entdecken </h3>{" "}
        </Col>
      </Row>
      <Row className="pt-4 d-flex justify-content-center">
        <Col sm="10" md="8" lg="6" xl="5" xxl="4" className="">
          <h5>Freilebende Flamingos</h5>
          <img
            className="image_size leftFloat me-3 "
            src="Flamingo.jpg"
            alt="Flamingo"
          />
          <p style={{ textAlign: "justify" }}>
            Die nördlichste Flamingokolonie der Welt kann man im Zwillbrocker
            Venn besuchen. Seit den 1980er Jahren brütet hier eine kleine, aber
            stetig wachsende Kolonie dieser faszinierenden rosa Vögel. Im Herbst
            verlassen die Flamingos jedoch die Region. Die Wintermonate
            verbringen die Flamingos in den Niederlanden.{" "}
          </p>
          <h5>Herrencreme</h5>
          <img
            className="image_size rightFloat ms-3 "
            src="herrencreme.jpg"
            alt="Herrencreme"
          />
          <p style={{ textAlign: "justify" }}>
            DAS Dessert im Münsterland. Das Original wird mit Dr. Oetker Bourbon
            Vanillepudding-Pulver gekocht, mit Potts Lager Rum verfeinert bevor
            kleingeschnittene Blockschokolade und geschlagene Sahne untergehoben
            werden. Man sollte gar nicht versuchen dies irgendwie zu ändern oder
            andere Produkte zu verwenden. Rezept gibt’s sehr gerne auf Anfrage.{" "}
          </p>
          <h5>Radfahren ohne Berge</h5>
          <img
            className="image_size leftFloat me-3 "
            src="radfahren.jpg"
            alt="Radfahren"
          />
          <p style={{ textAlign: "justify" }}>
            Wer das Münsterland mit dem Fahrrad entdeckt, kann völlig entspannt
            die schönen Parklandschaften mit ihren kleinen und großen Städten,
            Schlössern und Bauernhöfen genießen. Denn die "Pättkes", so nennen
            die Menschen im Münsterland ihre Radwege, sind zumeist eben und
            damit ideal für Genussradler.{" "}
          </p>
          <h5>100 Schlösser</h5>
          <img
            className="image_size rightFloat ms-3 "
            src="schloesser.jpg"
            alt="Schloesser"
          />
          <p style={{ textAlign: "justify" }}>
            Eine Königin unter den deutschen Radrouten ist die 100 Schlösser
            Route im Münsterland. Auf einer Länge von sage und schreibe 960
            Kilometern führt sie durch die grüne Parklandschaft der Region,
            vorbei an romantischen Burgen, Wasserschlössern und Herrenhäusern,
            aber auch an imposanten Schlossgärten und Parkanalagen. Nur der
            Bräutigam ist davon nicht so überzeugt und vermisst den
            herrschaftlichen Ausblick der bayrischen Schlösser.
          </p>
          <h5>Schützenfeste</h5>
          <img
            className="image_size leftFloat me-3 "
            src="schuetzen.jpg"
            alt="Schuetzen"
          />
          <p style={{ textAlign: "justify" }}>
            Es ist eine Tradition im Münsterland: das Schützenfest. Mit einem
            Kleinkalibergewehr schießen Männer auf einen bunt dekorierten
            Holzvogel. Wer den Vogel von der Stange holt, ist Schützenkönig und
            wird zusammen mit der Königin ausgiebig gefeiert. Traditionen werden
            beim Schützenverein großgeschrieben und Innovationen und
            gesellschaftliche Entwicklungen spielen eher keine Rolle.
          </p>
          <h5>Stielmus</h5>
          <img
            className="image_size rightFloat ms-3 "
            src="stielmus.jpg"
            alt="Stielmus"
          />
          <p style={{ textAlign: "justify" }}>
            Stielmus kennt man wahrscheinlich nur im Münster- und Rheinland.
            Sowohl die langen, dunkelgrünen Blätter, die hellen Stiele als auch
            die kleinen weißen Rübchen sind essbar und enthalten viele
            gesundheitsfördernde Inhaltsstoffe. Äußerlich sieht das Gemüse
            Mangold ähnlich. Der Stielmus-Eintopf von Oma Anny gehört zu den
            Lieblingsgerichten der Braut.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Guide;
