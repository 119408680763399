import { Container, Row, Col, Card, Button, Stack } from "react-bootstrap";

const Locations = (props) => {
  const handleButtonClick = (event) => {
    window.open(event.target.value);
  };

  return (
    <Container fluid className={`${props.className}`}>
      <Stack direction="horizontal" gap={3} className="justify-content-center">
        <Row className="justify-content-center">
          <Col
            {...props.displaySettingsThreeCols}
            className="mt-4 d-flex justify-content-center"
          >
            <Card style={{ width: "22rem" }}>
              <Card.Img variant="top" src="filetgran2.jpg" />
              <Card.Body>
                <Card.Title>Filetgran</Card.Title>
                <Card.Text>
                  <p style={{ textAlign: "justify" }}>
                    Hier findet unsere freie Trauung und die anschließende
                    Hochzeitsfeier statt. Direkt neben dem Bocholter Fluss Aa
                    werden wir hier einen hoffentlichen milden Herbsttag
                    genießen.
                  </p>
                  <p style={{ textAlign: "justify" }}>
                    Wir freuen uns sehr mit euch hier zu feiern. Falls ihr noch
                    Fragen zur Location habt, meldet euch bitte{" "}
                    <a href="/contact" className="text-secondary">
                      bei uns.
                    </a>
                  </p>
                </Card.Text>
              </Card.Body>
              <Card.Footer className="bg-white">
                <div className="text-center align-items-end">
                  <Row className="align-items-end mt-2">
                    <Col className="d-grid">
                      <Button
                        variant="outline-secondary"
                        value="https://restaurant-filetgran.de/"
                        className=""
                        onClick={handleButtonClick}
                      >
                        Website
                      </Button>
                    </Col>
                    <Col className="d-grid">
                      <Button
                        variant="outline-secondary"
                        value="https://www.google.com/maps?q=filetgran+bocholt&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjElc2qifH1AhVkSvEDHXN3AOEQ_AUoA3oECAIQBQ"
                        onClick={handleButtonClick}
                      >
                        Anfahrt
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card.Footer>
            </Card>
          </Col>{" "}
          <Col
            {...props.displaySettingsThreeCols}
            className="mt-4 d-flex justify-content-center"
          >
            <Card style={{ width: "22rem" }}>
              <Card.Img variant="top" src="hotelresidenz.jpg" />
              <Card.Body>
                <Card.Title>Hotel Residenz</Card.Title>
                <Card.Text>
                  <p style={{ textAlign: "justify" }}>
                    Wir haben Zimmer im Hotel Residenz reserviert, welche bis
                    zum 19.08.2022 gebucht werden können. Das Hotel ist nur 650
                    Meter von der Feierlocation entfernt.{" "}
                  </p>
                  <ul>
                    <li>
                      Das Doppelzimmer zum Sonderpreis von € 120,00 pro Zimmer /
                      pro Nacht / inkl. Frühstück.
                    </li>
                    <li>
                      Das Einzelzimmer zum Sonderpreis von € 90,00 pro Zimmer /
                      pro Nacht / inkl. Frühstück.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
              <Card.Footer className="bg-white">
                <div className="text-center">
                  <Row className="justify-content-center mt-2">
                    <Col className="d-grid">
                      <Button
                        variant="outline-secondary"
                        value="https://www.hotelresidenz.de/"
                        className=""
                        onClick={handleButtonClick}
                      >
                        Website
                      </Button>
                    </Col>
                    <Col className="d-grid">
                      <Button
                        variant="outline-secondary"
                        value="https://www.google.com/maps/place/Hotel+Residenz/@51.8321514,6.6127675,17z/data=!3m1!4b1!4m8!3m7!1s0x47b88763399ae135:0x2d10ee8f93f3ba51!5m2!4m1!1i2!8m2!3d51.8321507!4d6.6150083"
                        onClick={handleButtonClick}
                      >
                        Anfahrt
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card.Footer>
            </Card>
          </Col>{" "}
          <Col
            {...props.displaySettingsThreeCols}
            className="mt-4 d-flex justify-content-center"
          >
            <Card style={{ width: "22rem" }}>
              <Card.Img variant="top" src="motelb.jpg" />
              <Card.Body>
                <Card.Title>Motel B</Card.Title>
                <Card.Text>
                  <p style={{ textAlign: "justify" }}>
                    Das Motel B hat keinen Bezug zu Motel One, ist aber
                    ebenfalls eine Empfehlung und auch hier sind Zimmer
                    reserviert. Das Motel B ist nur 550 Meter vom Filetgran
                    entfernt.{" "}
                  </p>
                  <ul>
                    <li>
                      Das Doppelzimmer kostet hier € 76,00 pro Zimmer / pro
                      Nacht.
                    </li>
                    <li>
                      Das Einzelzimmer kostet € 64,00 pro Zimmer / pro Nacht.
                    </li>
                  </ul>
                  <p style={{ textAlign: "justify" }}>
                    Die Gäste im Motel B können im Hotel Residenz frühstücken
                    für € 14,00 pro Person / pro Tag.
                  </p>
                </Card.Text>
              </Card.Body>
              <Card.Footer className="bg-white">
                <div className="text-center">
                  <Row className="justify-content-center mt-2">
                    <Col className="d-grid">
                      <Button
                        variant="outline-secondary"
                        value="https://www.motel-b.de/"
                        className=""
                        onClick={handleButtonClick}
                      >
                        Website
                      </Button>
                    </Col>
                    <Col className="d-grid">
                      <Button
                        variant="outline-secondary"
                        value="https://www.google.com/maps/place/Motel+B/@51.832529,6.6124013,17z/data=!3m1!4b1!4m8!3m7!1s0x47b88770197e1681:0xe995304eaada3923!5m2!4m1!1i2!8m2!3d51.83253!4d6.6146032"
                        onClick={handleButtonClick}
                      >
                        Anfahrt
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>{" "}
      </Stack>
    </Container>
  );
};

export default Locations;
