import { Container, Row, Col, Accordion } from "react-bootstrap";

const Useful = (props) => {
  return (
    <Container fluid className={`${props.className}`}>
      <Row className="pt-4 justify-content-center">
        <Col {...props.displaySettingsMain}>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Gibt es einen Dresscode?</Accordion.Header>
              <Accordion.Body>
                <p>
                  Wir haben uns gegen einen strengen Dresscode entschieden und
                  möchten, dass sich jeder Gast wohlfühlt.
                </p>

                <p>
                  Als Orientierung empfehlen wir ein herbstlich/festliches
                  Outfit (die Damen sehr gerne auch im langen Kleid und die
                  Herren im Anzug). Die Farben Weiß und Beige bleiben der Braut
                  vorbehalten.
                </p>

                <p>
                  Da bei schönem Wetter auch die Terrasse genutzt werden kann,
                  denkt bitte auch an eine warme Jacke.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Was wünscht ihr euch?</Accordion.Header>
              <Accordion.Body>
                Unser Heim in München ist tatsächlich klein. Es passt auch
                nichts mehr rein. Drum schenkt uns keine schönen Sachen, lasst
                lieber unser Sparschwein für die Flitterwochen lachen.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Wie handhabt ihr es mit den Fotos?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Bitte verzichtet während der freien Trauung auf Fotos und
                  Videoaufnahmen mit dem eigenen Handy. Wir haben eine tolle
                  Fotografin engagiert. Später freuen wir uns, wenn ihr
                  auch eigene Schnapschüsse der Feier erstellt und diese
                  anschließend mit uns teilt.
                </p>

                <p>
                  Eine Auswahl der Bilder werden wir ca. 4-6 Wochen nach der
                  Feier hier auf der Webseite mit euch teilen.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Wo kann ich am besten parken?</Accordion.Header>
              <Accordion.Body>
                <p>
                  Bitte haltet die Parkplätze direkt vorm Restaurant Filetgran
                  für die älteren Gäste und das Brautauto frei.
                </p>

                <p>
                  Wir empfehlen den Parkplatz "Berliner Platz" (Meckenemstraße
                  12, 46395 Bocholt): Von hier aus ist man mit 5 Minuten Gehweg
                  am Filetgran. Es sind auch begrenzt Parkplätze in den Straßen
                  rund um das Restaurant verfügbar.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                Wie lange wird im Münsterland gefeiert?
              </Accordion.Header>
              <Accordion.Body>
                DJ und Location sind bis in den frühen Morgen gebucht. Ob wir
                die 4 Uhr tatsächlich knacken? Nach den Corona-Jahren mit
                wenigen Möglichkeiten zu feiern und zu tanzen, brauchen wir wohl
                alle vorher ein "Party-Bootcamp" und viel Schlaf vorab. :D Egal
                wie lange die Party am Ende geht, wir freuen uns sehr mit euch
                gemeinsam zu feiern!!
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
      <Row className="mt-5 justify-content-center">
        <Col {...props.displaySettingsMain}>
          <p style={{ textAlign: "justify" }}>
            Bei weiteren Fragen wendet euch sehr gerne direkt an{" "}
            <a href="/contact" className="text-secondary">
              uns
            </a>{" "}
            oder unsere{" "}
            <a href="/contact" className="text-secondary">
              Trauzeugen
            </a>
            . Für Fragen bezüglich Bocholt und Umgebung kann auch sehr gerne der{" "}
            <a href="/contact" className="text-secondary">
              Bruder
            </a>{" "}
            der Braut gefragt werden.{" "}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Useful;
