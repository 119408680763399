import { Container, Row, Col, Table } from "react-bootstrap";

const Event = (props) => {
  return (
    <Container fluid className={`${props.className}`}>
      <Row className="pt-4 justify-content-center">
        <Col {...props.displaySettingsMain}>
          <h4 className="text-center">Vorabend – 01.10.2022</h4>
        </Col>
      </Row>
      <Row className="pt-4 justify-content-center">
        <Col {...props.displaySettingsMain}>
          <Table responsive hover>
            <tbody>
              <tr>
                <td className="d-none d-sm-table-cell">
                  <img src="talking.png" alt="" className="m-3" />
                </td>
                <td>
                  <p className="m-4">
                    <strong>18:00 - 21:00</strong>
                  </p>
                </td>
                <td>
                  <p className="my-3">
                  Allen, die schon am Samstag in Bocholt sind, möchten wir mit einem lockeren Get-Together im Café "freudenhaus" (Europaplatz 11, Bocholt) eine Möglichkeit geben zum Kennenlernen und gemeinsam einen entspannten Abend zu verbringen. Meldet euch bitte kurz beim Brautpaar wenn ihr mit dabei sein möchtet. 
                  </p>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="pt-4 justify-content-center">
        <Col {...props.displaySettingsMain}>
        <h4 className="text-center">Die Hochzeit – 02.10.2022</h4>
        </Col>
      </Row>
      <Row className="pt-4 justify-content-center">
        <Col {...props.displaySettingsMain}>
          <Table responsive hover>
            <tbody>
            <tr>
                <td className="d-none d-sm-table-cell">
                  <img src="Ballons.png" alt="" className="m-3" />
                </td>
                <td>
                  <p className="m-4">
                    <strong>14:30</strong>
                  </p>
                </td>
                <td>
                  <p className="my-3">
                  Bevor die freie Trauung beginnt, könnt ihr entspannt im Restaurant "Filetgran" ankommen und eure Jacken und Taschen ablegen.
                  </p>
                </td>
              </tr>
            <tr>
                <td className="d-none d-sm-table-cell">
                  <img src="church.png" alt="" className="m-3" />
                </td>
                <td>
                  <p className="m-4">
                    <strong>15:00</strong>
                  </p>
                </td>
                <td>
                  <p className="my-3">
                  Um 15 Uhr beginnt unsere freie Trauung. Während der Trauung möchten wir euch bitten auf Foto- und Videoaufnahmen mit dem eigenen Handy zu verzichten. Unsere Fotografin wird alle Momente festhalten. 
                  </p>
                </td>
              </tr>
              <tr>
                <td className="d-none d-sm-table-cell">
                  <img src="empfang.png" alt="" className="m-3" />
                </td>
                <td>
                  <p className="m-4">
                    <strong>16:00</strong>
                  </p>
                </td>
                <td>
                  <p className="my-3">
                  Nach der Trauung heißt es anstoßen und gratulieren. Bei herbstlich, sonnigem Wette kann auch die Terrasse direkt an der Aa genutzt werden.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="d-none d-sm-table-cell">
                  <img src="camera.png" alt="" className="m-3" />
                </td>
                <td>
                  <p className="m-4">
                    <strong>17:00</strong>
                  </p>
                </td>
                <td>
                  <p className="my-3">
                  Wenn das Wetter mitspielt, heißt es nun noch einmal für alle Gäste einen kleinen Ausflug (nur 2-3 Gehminuten) an die Aa zu machen für das Gruppenfoto mit dem Brautpaar. 
                  </p>
                </td>
              </tr>
              <tr>
                <td className="d-none d-sm-table-cell">
                  <img src="dinner.png" alt="" className="m-3" />
                </td>
                <td>
                  <p className="m-4">
                    <strong>18:30</strong>
                  </p>
                </td>
                <td>
                  <p className="my-3">
                  Wir haben uns gemeinsam mit dem Team des Filetgran ein leckeres Menü überlegt mit vegetarischen Alternativen. Solltet ihr euch vegan ernähren oder Unverträglichkeiten haben, gebt dies bitte bei der Zusage mit an oder schreibt uns. 
                  </p>
                </td>
              </tr>
              <tr>
                <td className="d-none d-sm-table-cell">
                  <img src="dancing.png" alt="" className="m-3" />
                </td>
                <td>
                  <p className="m-4">
                    <strong>22:00</strong>
                  </p>
                </td>
                <td>
                  <p className="my-3">
                  Endlich wieder tanzen und feiern! Nach der Coronapause freuen wir uns umso mehr gemeinsam mit euch zu feiern. Zur Stärkung zwischendrin wird es auch einen Mitternachtssnack geben. 
                  </p>
                </td>
              </tr>
              <tr>
                <td className="d-none d-sm-table-cell">
                  <img src="moon.png" alt="" className="m-3" />
                </td>
                <td>
                  <p className="m-4">
                    <strong>04:00</strong>
                  </p>
                </td>
                <td>
                  <p className="my-3">
                  Wenn die Füße uns tatsächlich so lange durch die Nacht und über die Tanzfläche tragen, heißt es nun mit dem letzten Song den Abend zu beenden und Tschüss zu sagen und uns ins Eheleben zu verabschieden. 
                  </p>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default Event;
