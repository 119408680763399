import {
  Container,
  Row,
  Col,
  Form,
  Button,
  ButtonGroup,
  FloatingLabel,
  Spinner,
} from "react-bootstrap";
import { useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";

const Rsvp = (props) => {
  const [showForm, setShowForm] = useState(true);
  const [acceptedInvitation, setAcceptedInvitation] = useState(true);
  const [mail, setMail] = useState("");
  const [comment, setComment] = useState("");
  const [loadData, setLoadData] = useState(false);
  const [showError, setShowError] = useState(false);
  const [persons, setPersons] = useState([
    {
      key: "1",
      firstName: "",
      lastName: "",
      vegetarian: false,
      kid: false,
      kidAge: 0,
    },
  ]);
  const [validated, setValidated] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();

    const form = event.currentTarget;
    if (!form.checkValidity()) {
      setValidated(true);
      event.stopPropagation();
    } else {
      setLoadData(true);

      await sendData().then((res) => {
        console.log("res: " + res);

        if (res) {
          console.log("Zusage: " + acceptedInvitation);
          persons.map((person) =>
            console.log(
              "name: " +
                person.firstName +
                " " +
                person.lastName +
                " Vegetarier: " +
                person.vegetarian +
                " Kind: " +
                person.kid,
              " Kind Alter: " + person.kidAge
            )
          );

          console.log("E-Mail: " + mail);
          console.log("Kommentar: " + comment);

          setPersons([
            {
              key: "1",
              firstName: "",
              lastName: "",
              vegetarian: false,
              kid: false,
              kidAge: 0,
            },
          ]);
          setMail("");
          setComment("");
          setShowForm(false);
          setValidated(false);
          setLoadData(false);
        } else {
          event.stopPropagation();
          setShowError(true);
          console.log("error occured sorry");
          setLoadData(false);
        }
      });
    }
  }

  const showFormHandler = () => {
    setShowForm(true);
  };

  async function sendData() {
    let date_ob = new Date().toString();
    // var responseStatus = true;
    var requestOptionsArray = [];

    setShowError(false);

    /*
    persons.map(async (person) => {
      requestOptionsArray.push({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          Item1: {
            mailnames: mail + person.firstName + person.lastName,
            accepted: acceptedInvitation,
            firstname: person.firstName,
            lastname: person.lastName,
            vegetarian: person.vegetarian,
            kid: person.kid,
            kidAge: person.kidAge,
            mail: mail,
            comment: comment,
            date: date_ob,
          },
        }),
      });
    });
*/
    /*
    persons.map(async (person) => {
      requestOptionsArray.push({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          Items: [
            {
              mailnames: mail + person.firstName + person.lastName,
              accepted: acceptedInvitation,
              firstname: person.firstName,
              lastname: person.lastName,
              vegetarian: person.vegetarian,
              kid: person.kid,
              kidAge: +person.kidAge,
              mail: mail,
              comment: comment,
              date: date_ob,
            },
          ],
        }),
      });
    });
*/

    persons.map(async (person) => {
      requestOptionsArray.push({
        mailnames: mail + person.firstName + person.lastName,
        accepted: acceptedInvitation,
        firstname: person.firstName,
        lastname: person.lastName,
        vegetarian: person.vegetarian,
        kid: person.kid,
        kidAge: +person.kidAge,
        mail: mail,
        comment: comment,
        date: date_ob,
      });
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        Items: requestOptionsArray,
      }),
    };

    console.log(
      "calling with: https://9xx8h4sgpe.execute-api.eu-central-1.amazonaws.com/default/weddingRSVP"
    );

    const resp = await fetch(
      "https://9xx8h4sgpe.execute-api.eu-central-1.amazonaws.com/default/weddingRSVP",
      requestOptions
    ).catch((err) => {
      console.log(err);
    });
    //  console.log(resp.status);
    // return resp.status;

    /*
    const responseCodes = await Promise.all(
      requestOptionsArray.map(async (requestOptions) => {
        console.log(requestOptions);
        const resp = await fetch(
          "https://9xx8h4sgpe.execute-api.eu-central-1.amazonaws.com/default/weddingRSVP",
          requestOptions
        ).catch((err) => {
          console.log(err);
        });
        console.log(resp.status);
        return resp.status;
      })
    );
*/
    /*
    var returnValue = true;
    responseCodes.forEach((code) => {
      console.log(code);
      if (code >= 400 && code < 600) returnValue = false;
    });
    return returnValue;
    */

    if (resp.status >= 400 && resp.status < 600) return false;
    else return true;
  }

  const onChangeMailHandler = (event) => {
    setMail(event.target.value);
  };

  const onChangeCommentHandler = (event) => {
    setComment(event.target.value);
  };

  const onAcceptInvitationHandler = (event) => {
    if (event.target.name === "sayyes") setAcceptedInvitation(true);
    else setAcceptedInvitation(false);
  };

  const onAddPersonHandler = () => {
    setPersons((persons) => [
      ...persons,
      {
        key: persons.length + 1,
        firstName: "",
        lastName: "",
        vegetarian: false,
        kid: false,
        kidAge: 0,
      },
    ]);
  };

  const onRemovePersonHandler = () => {
    setPersons(persons.filter((item) => item.key !== persons.length));
  };

  const updatePersonHandler = (key, type) => (event) => {
    setPersons(
      persons.map((person) => {
        if (+key !== +person.key) {
          return person;
        } else {
          if (type === "firstName")
            return {
              key: key,
              firstName: event.target.value,
              lastName: person.lastName,
              vegetarian: person.vegetarian,
              kid: person.kid,
              kidAge: person.kidAge,
            };

          if (type === "lastName")
            return {
              key: key,
              firstName: person.firstName,
              lastName: event.target.value,
              vegetarian: person.vegetarian,
              kid: person.kid,
              kidAge: person.kidAge,
            };

          if (type === "vegetarian")
            return {
              key: key,
              firstName: person.firstName,
              lastName: person.lastName,
              vegetarian: event.target.checked,
              kid: person.kid,
              kidAge: person.kidAge,
            };

          if (type === "kid") {
            if (!event.target.checked) person.kidAge = 0;
            return {
              key: key,
              firstName: person.firstName,
              lastName: person.lastName,
              vegetarian: person.vegetarian,
              kid: event.target.checked,
              kidAge: person.kidAge,
            };
          }

          if (type === "kidAge")
            return {
              key: key,
              firstName: person.firstName,
              lastName: person.lastName,
              vegetarian: person.vegetarian,
              kid: person.kid,
              kidAge: event.target.value,
            };
        }
        return null;
      })
    );
  };

  return (
    <Container fluid className={`${props.className}`}>
      {showForm && !loadData && (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="pt-4 justify-content-center">
            <Col {...props.displaySettingsMain}>
              <ButtonGroup aria-label="Basic example">
                <Button
                  variant={acceptedInvitation ? "dark" : "outline-dark"}
                  name="sayyes"
                  onClick={onAcceptInvitationHandler}
                  checked
                >
                  Zusage
                </Button>
                <Button
                  variant={!acceptedInvitation ? "dark" : "outline-dark"}
                  name="sayno"
                  onClick={onAcceptInvitationHandler}
                >
                  Absage
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Form.Group controlId="personsForm">
            {persons.map((person, index) => (
              <Row
                className="pt-4 justify-content-center"
                key={`row${person.key}`}
              >
                <Col sm="4" lg="4" xl="2" xxl="1" key={`col1${person.key}`}>
                  <FloatingLabel
                    controlId="floatingInputVorname"
                    label="Vorname"
                  >
                    <Form.Control
                      required
                      type="text"
                      className="border-dark"
                      placeholder="Vorname"
                      value={person.firstName}
                      key={`firstName${person.key}`}
                      onChange={updatePersonHandler(index + 1, "firstName")}
                    />
                  </FloatingLabel>
                </Col>
                <Col sm="4" lg="4" xl="2" xxl="1" key={`col2${person.key}`}>
                  <FloatingLabel
                    controlId="floatingInputNachname"
                    label="Nachname"
                  >
                    <Form.Control
                      required
                      type="text"
                      className="border-dark"
                      placeholder="Name"
                      value={person.lastName}
                      key={`lastName${person.key}`}
                      onChange={updatePersonHandler(index + 1, "lastName")}
                    />
                  </FloatingLabel>
                </Col>
                {person.kid && (
                  <Col sm="2" lg="2" xl="2" xxl="2" key={`col5${person.key}`}>
                    <FloatingLabel
                      controlId="floatingInputAlterKind"
                      label="Alter Kind"
                    >
                      <Form.Control
                        type="number"
                        className="border-dark"
                        placeholder="Alter Kind"
                        value={person.kidAge}
                        onChange={updatePersonHandler(index + 1, "kidAge")}
                      />
                    </FloatingLabel>
                  </Col>
                )}
                <Col sm="2" lg="2" xl="2" xxl="2" key={`col3${person.key}`}>
                  <Form.Check
                    type="switch"
                    id="vegetarian"
                    label="Vegetarier"
                    key={`vegetarian${person.key}`}
                    checked={person.vegetarian}
                    onChange={updatePersonHandler(index + 1, "vegetarian")}
                  />
                  <Form.Check
                    type="switch"
                    id="kid"
                    label="Ist ein Kind?"
                    key={`kid${person.key}`}
                    checked={person.kid}
                    onChange={updatePersonHandler(index + 1, "kid")}
                  />
                </Col>
                {!person.kid && (
                  <Col
                    sm="2"
                    lg="2"
                    xl="2"
                    xxl="2"
                    key={`col6™${person.key}`}
                  ></Col>
                )}
              </Row>
            ))}
          </Form.Group>
          <Row className="mx-2 justify-content-center">
            <Col {...props.displaySettingsMain} className="g-3">
              <i
                className="bi-person-plus-fill text-dark"
                style={{ fontSize: `25px` }}
                onClick={onAddPersonHandler}
              >
                {" "}
              </i>{" "}
              {persons.length > 1 && (
                <i
                  className="bi-person-dash-fill text-dark"
                  style={{ fontSize: `25px` }}
                  onClick={onRemovePersonHandler}
                ></i>
              )}
            </Col>
          </Row>
          <Row className="pt-4 justify-content-center">
            <Col {...props.displaySettingsMain}>
              <Form.Label>
                Für wichtige Information hinterlasst bitte eure E-Mail Adresse:
              </Form.Label>
            </Col>
          </Row>{" "}
          <Row className="pt-0 justify-content-center">
            <Col {...props.displaySettingsMain}>
              <FloatingLabel controlId="floatingInputAlterKind" label="E-Mail">
                <Form.Control
                  required
                  type="email"
                  className="border-dark"
                  placeholder="name@beispiel.de"
                  value={mail}
                  onChange={onChangeMailHandler}
                />
              </FloatingLabel>
            </Col>
          </Row>{" "}
          <Row className="pt-4 justify-content-center">
            <Col {...props.displaySettingsMain}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>
                  Möchtet ihr uns sonst noch etwas mitteilen? (z.B. Allergien,
                  veganer Menüwunsch, ...)
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  className="border-dark"
                  value={comment}
                  onChange={onChangeCommentHandler}
                />
              </Form.Group>
              <Button type="submit" variant="dark">
                Abschicken
              </Button>
            </Col>
          </Row>
        </Form>
      )}
      {loadData && (
        <Row className="pt-4 justify-content-center">
          <Col {...props.displaySettingsMain}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Lade...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      {showError && (
        <Row className="pt-4 justify-content-center">
          <Col {...props.displaySettingsMain}>
            <p className="text-danger">
              Leider gab es einen Fehler bei der Übermittlung :-({" "}
              <span>
                Versuche es bitte einfach noch einmal mit "Abschicken" oder gib
                uns per{" "}
                <a href="mailto:hochzeit@bloede-idee.de?body=Hallo%20Sarah,%20Hallo%20Andreas,%0D%0A%0D%0Afolgend%20meine%20Rückmeldung%20zur%20Hochzeit:%0D%0A%0D%0AZusage: Ja/Nein%0D%0A%0D%0APerson 1: Vorname, Nachname, Vegetarier Ja/Nein, (Alter wenn Kind)%0D%0A%0D%0APerson 2: Vorname, Nachname, Vegetarier: Ja/Nein, (Alter wenn Kind)%0D%0A%0D%0AE-Mail:%0D%0A%0D%0ASonstiges:%0D%0A%0D%0A%0D%0A%0D%0A">
                  Mail Bescheid
                </a>
                .
              </span>{" "}
            </p>
          </Col>
        </Row>
      )}
      {!showForm && (
        <Row className="pt-4 justify-content-center">
          <Col {...props.displaySettingsMain}>
            <p>
              Vielen Dank für die Rückmeldung.{" "}
              {acceptedInvitation && (
                <span> Wir freuen uns auf den gemeinsamen Tag im Oktober.</span>
              )}{" "}
              {!acceptedInvitation && (
                <span>Schade, dass wir nicht gemeinsam feiern können.</span>
              )}
            </p>

            <Button type="button" variant="dark" onClick={showFormHandler}>
              Für weitere Gäste zu/absagen.
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Rsvp;
