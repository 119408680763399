import { Container, Row, Col, Stack, Card } from "react-bootstrap";

const Contact = (props) => {
  return (
    <Container fluid className={`${props.className}`}>
      <Stack direction="horizontal" gap={3} className="justify-content-center">
        <Row className="justify-content-center mt-2 text-center">
          <Col
            {...props.displaySettingsFourCols}
            className="d-flex justify-content-center"
          >
            <Card style={{ width: "20rem" }} className="mb-3 p-3">
              <Card.Img variant="top" src="IMG_6085.jpg" />
              <Card.Body>
                <Card.Title>Andreas &amp; Sarah</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Das Brautpaar
                </Card.Subtitle>
                <Card.Text>
                  <Row>
                  <Col className="d-flex justify-content-center">
                      <p>
                        <a
                          href="mailto:andreas@sarah-und-andreas.de"
                          className="text-secondary px-2"
                        >
                          <i
                            className="bi-envelope-fill"
                            style={{ fontSize: `22px` }}
                          ></i>
                        </a>
                        <a
                          href="tel:+4917620188076"
                          className="text-secondary  px-2"
                        >
                          <i
                            className="bi-telephone-fill"
                            style={{ fontSize: `22px` }}
                          ></i>
                        </a>
                      </p>
                    </Col>
                    <Col className="d-flex justify-content-center">
                      <p>
                        <a
                          href="mailto:sarah@sarah-und-andreas.de"
                          className="text-secondary px-2"
                        >
                          <i
                            className="bi-envelope-fill"
                            style={{ fontSize: `22px` }}
                          ></i>
                        </a>
                        <a
                          href="tel:+4915165138021"
                          className="text-secondary  px-2"
                        >
                          <i
                            className="bi-telephone-fill"
                            style={{ fontSize: `22px` }}
                          ></i>
                        </a>
                      </p>
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col>
                      <p style={{ textAlign: "justify" }}>
                        Die Münsterländerin und der Bayer. Verliebt im August
                        2017, verlobt im September 2021 und nun verheiratet im
                        Juli und Oktober 2022.
                      </p>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col
            {...props.displaySettingsFourCols}
            className="d-flex justify-content-center"
          >
            <Card style={{ width: "20rem" }} className="mb-3 p-3">
              <Card.Img variant="top" src="jr.jpg" />
              <Card.Body>
                <Card.Title>John Robert Fritzsch</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Trauzeuge von Sarah
                </Card.Subtitle>
                <Card.Text>
                  <p>
                    <a
                      href="mailto:John.robert.fritzsch@googlemail.com"
                      className="text-secondary px-2"
                    >
                      <i
                        className="bi-envelope-fill"
                        style={{ fontSize: `22px` }}
                      ></i>
                    </a>
                    <a
                      href="tel:+491784122251"
                      className="text-secondary  px-2"
                    >
                      <i
                        className="bi-telephone-fill"
                        style={{ fontSize: `22px` }}
                      ></i>
                    </a>
                  </p>
                  <p style={{ textAlign: "justify" }}>
                  Freund von Sarah seit ihrem ersten Monat in München. John-Robert und seine Freundin Angelina waren die "Corona-Buddies" des Brautpaars während der Lockdowns.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>{" "}
          <Col
            {...props.displaySettingsFourCols}
            className="d-flex justify-content-center"
          >
            <Card style={{ width: "20rem" }} className="mb-3 p-3">
              <Card.Img variant="top" src="simon.jpg" />
              <Card.Body>
                <Card.Title>Simon Deml</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Trauzeuge von Andreas
                </Card.Subtitle>
                <Card.Text>
                  <p>
                    <a
                      href="mailto:simondeml@gmx.de"
                      className="text-secondary px-2"
                    >
                      <i
                        className="bi-envelope-fill"
                        style={{ fontSize: `22px` }}
                      ></i>
                    </a>
                    <a
                      href="tel:+4917660008754"
                      className="text-secondary  px-2"
                    >
                      <i
                        className="bi-telephone-fill"
                        style={{ fontSize: `22px` }}
                      ></i>
                    </a>
                  </p>
                  <p style={{ textAlign: "justify" }}>
                  Schulfreund und langjähriger Wegbegleiter von Andreas. Funfact: die beiden wurden beim Ausgehen auch mal gerne für ein Pärchen gehalten. 
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col
            {...props.displaySettingsFourCols}
            className="d-flex justify-content-center"
          >
            <Card style={{ width: "20rem" }} className="mb-3 p-3">
              <Card.Img variant="top" src="jens.jpg" />
              <Card.Body>
                <Card.Title>Jens Dönnebrink</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Bruder von Sarah
                </Card.Subtitle>
                <Card.Text>
                  <p>
                    <a href="mailto:jens.doennebrink@googlemail.com" className="text-secondary px-2">
                      <i
                        className="bi-envelope-fill"
                        style={{ fontSize: `22px` }}
                      ></i>
                    </a>
                    <a href="tel:+491736795730" className="text-secondary  px-2">
                      <i
                        className="bi-telephone-fill"
                        style={{ fontSize: `22px` }}
                      ></i>
                    </a>
                  </p>
                  <p style={{ textAlign: "justify" }}>
                  Der Bruder der Braut lebt mit seiner Frau und Tochter in Bocholt und hilft gerne weiter, wenn ihr Fragen oder Ideen für und in Bocholt habt. 
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>{" "}
      </Stack>
    </Container>
  );
};

export default Contact;
