import { Container, Nav, Navbar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const Header = (props) => {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">
        <img
        src="Logo_frei.png"
        width="100"
        height="56"
        className="d-inline-block align-top"
        alt="Wedding"
      />
</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/welcome">Willkommen</Nav.Link>
            <Nav.Link href="/event">Ablauf</Nav.Link>
            <Nav.Link href="/locations">Orte/Hotels</Nav.Link>
            <Nav.Link href="/guide">Münsterland</Nav.Link>
           <Nav.Link href="/fotos">Fotos</Nav.Link>
         {/*<Nav.Link href="/useful">Wichtiges</Nav.Link> */ }  
            <Nav.Link href="/contact">Kontakt</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
      <Navbar.Text>
        <a href="#" onClick={props.onSignOut}>Ausloggen</a>
      </Navbar.Text>
    </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
