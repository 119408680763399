import { Container, Row, Col, Carousel } from "react-bootstrap";

const Welcome = (props) => {
  return (
    <Container fluid className={`${props.className}`}>
      <Row className="pt-4 justify-content-center">
        <Col {...props.displaySettingsMain}>
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="brautpaar.jpg?text=First slide&bg=373940"
                alt="First slide"
              />
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="filetgrantable.jpg?text=Second slide&bg=282c34"
                alt="Second slide"
              />

              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="bocholt.jpg?text=Third slide&bg=20232a"
                alt="Third slide"
              />

              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      <Row className="pt-4 justify-content-center">
        <Col {...props.displaySettingsMain}>
          <p>
            Herzlich Willkommen auf unserer Hochzeits-Webseite. Alle wichtigen
            Informationen sowie die Möglichkeit zur Zu- oder Absage findet ihr
            unter den Menüpunkten oben. Nach der Feier möchten wir über diese
            Seite auch eine Auswahl der Hochzeitsfotos für euch bereitstellen.
          </p>
          <p>
            Wir freuen uns auf einen wunderschönen Tag mit Euch am 02. Oktober
            2022.
          </p>
          <p>Sarah und Andreas</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Welcome;
